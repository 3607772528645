
.iconButtonAppBar {
    border-radius: 36px !important;
    padding: 6px !important;
    font-size: 2rem !important;
}

.infoPerfil {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;

    .img_perfil_question_mobile {
        width: 4rem !important;
        height: 4rem !important;
        
        h6 {
            font-size: 1.4rem !important;
        }
    }

    .name_studenty {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    .ra_studenty {
        font-size: 0.8rem !important;
        font-weight: bold !important;
        color: #6f6f6f !important;
    }
}

.iconInNavigate {
    color: #707070 !important;
}

.itemNavigate {
    font-weight: bold !important;
}

.active {
    color: #bd0e0e !important;

}

.area_notify_header_mobile {
    min-height: 5rem !important;
    max-height: 24rem !important;
    overflow-y: auto !important;
    max-width: 90vw !important;

    .item_list_request {
        display: flex !important;
        align-items: center !important;
        color: #2c2c2c !important;
        padding: 0.2rem !important;

        >svg {
            color: #dadada !important;
            font-size: 0.8rem !important;
            margin-right: 0.2rem !important;
        }

        .text_list_request {
            max-width: 100% !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;

            font-family: 'Roboto' !important;
            font-size: 0.9rem !important;
            font-weight: 500 !important;
        }
    }

    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background: #7070707d;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #2c2c2c7a;
        border-radius: 20px;
        border: none;
    }
}

@media screen and (max-width: 840px) {
    .iconButtonAppBar {
        font-size: 1.6rem !important;
        padding: 4px !important;
    }
}