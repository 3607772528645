.middle {
    border: solid red;
    width: 540px;
    padding: 12px 24px;
}

.container-login {

    height: 90vh;    
    margin-top: 2vh;
    margin-bottom: 2vh;    

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    .seccao-banner-login {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .banner-login {
            width: 100%;
            height: 30%;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            top: 30%;

            img {
                position: absolute;
                z-index: 3;
            }

            #lottie {
                position: absolute;
                background-color: transparent;
                width: 105%;
                height: 105%;
                display: block;
                overflow: hidden;
                transform: translate3d(0, 0, 0);
                text-align: center;
                z-index: 2;
                opacity: 1;
            }
        }

        .textos-banner-login {

            text-justify: center;
            position: absolute;
            bottom: 29.5%;

            h3 {
                font-size: 2.2rem !important;
                font-weight: 700 !important;
                text-align: center !important;
            }

            p {
                font-size: 1.2rem !important;
                color: #494747 !important;
                text-align: center !important;
            }

        }
    }

    .seccao-formulario-login {
        margin: 12% 0;

        label {
            display: block;
            margin-bottom: 10px;
            color: #0000008A;
        }

        h2 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
        }

        #btnLogin {
            margin-bottom: 10px;
        }

        .footer-formulario-login {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 24px;
            margin-bottom: 20px;

            a {
                display: block;
                margin-bottom: 10px;
                text-decoration: none;
            }
        }

        .MuiFormControl-root {
            padding-bottom: 10px !important;
        }

        .MuiFormControlLabel-root {
            margin: 0 16px 0 -11px;
        }

    }

}

@media screen and (max-width: 960px) {
    .container-login {
        .seccao-banner-login {
            display: none;
        }

        .seccao-formulario-login {

            margin: 0px;

            .MuiFormControl-root {
                padding-bottom: 10px !important;
            }

            .MuiFormControlLabel-root {
                margin: 0 16px 0 -11px;
            }

        }
    }
}


.login-side {
    padding: 24px !important;
}


.footer-login {
    a {
        text-decoration: none;
    }
}