.header_questions {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 4rem !important;


    .salutation{
        font-size: 1.6rem !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }

    .what {
        font-size: 2rem !important;
        font-weight: 500 !important;
    }

    & > button {
        border-radius: 36px !important;
        font-weight: bold !important;
        font-size: 1rem !important;
        margin-top: 1rem !important;
    }

    &::after {
        margin-top: 2rem !important;
        content: "" !important;
        width: 68vw !important;
        height: 2px !important;
        background-color: #e0e0e0 !important;
    }
}

.body_questions {
    .filters {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        padding: 1.4rem 0px !important;
        
        div + div {
            margin-left: 0.8rem !important;
        }
    }

    .views_questions {
        & > h5 {
            font-weight: bold !important;
            color: #2c2c2c !important;
            display: flex !important;
            align-items: center !important;
            gap: 0.5rem !important;
        }

        .view_question {
            border: 1px solid #2c2c2c !important;
            border-radius: 4px !important;
            padding: 0.5rem !important;
            margin-top: 0.5rem !important;
            cursor: pointer !important;
            background-color: #fff !important;
            transition: filter 0.2s !important;

            &:hover {
                filter: brightness(0.98) !important;
            }

            & > h6 {
                font-family: 'Roboto' !important;
                font-weight: 500 !important;
            }

            .footer_question_view {
                display: flex !important;
                justify-content: space-between !important;
                align-items: center !important;
                margin-top: 0.8rem !important;

                & > h6 {
                    color: #2c2c2c !important;
                    border-radius: 4px !important;
                    padding: 4px !important;
                    font-family: 'Roboto' !important;
                    font-size: 0.8rem !important;
                }

                .avaliations {
                    display: flex !important;

                    h6 + h6 {
                        margin-left: 0.5rem !important;
                    }

                    & > h6 {
                        font-family: 'Roboto' !important;
                        display: flex !important;
                        align-items: center !important;
                        gap: 2px !important;
                        font-weight: 500 !important;
                    }
                    
                }
            }
        }
    }
}

.iconStar {
    color: #d6a40a !important;
}

.iconHeart {
    color: #bd0e0e !important;
}

.titleDialogQuestion {
    color: #fff !important;
    text-align: center !important;
    font-size: 1.6rem !important;
    text-transform: uppercase !important;
    font-family: "Roboto" !important;
    font-weight: bold !important;
}

.await_send_question {
    color: #0000007d !important;
    font-weight: bold !important;
    padding: 0.4rem 0 !important;
}

@media screen and (max-width: 840px) {
    .header_questions {
        margin-top: 1rem !important;
    
    
        .salutation{
            font-size: 1.2rem !important;
        }
    
        .what {
            font-size: 1.4rem !important;
        }
    
        & > button {
            font-size: 0.8rem !important;
            margin-top: 0.4rem !important;
        }
    
        &::after {
            margin-top: 1.2rem !important;
        }
    }

    .titleDialogQuestion {
        font-size: 1rem !important;
    }

    .body_questions {
        .filters {
            div + div {
                margin-left: 0.4rem !important;
            }
        }
    
        .views_questions {
            & > h5 {
                font-size: 1rem !important;
            }
    
            .view_question {
                & > h6 {
                    font-size: 0.8rem !important;
                }
    
                .footer {
                    .avaliations {
                        & > h6 {
                            font-size: 0.8rem !important;
                        }
                        
                    }
                }
            }
        }
    }
}