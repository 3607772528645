.area_response {
    border: 1px solid #2c2c2c !important;
    border-radius: 4px !important;
    padding: 0.6rem !important;
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 0.4rem !important;

    .header {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        
        & > :first-child {
            color: #246f0c !important;
            font-family: 'Roboto' !important;
            font-weight: 500 !important;
        }

        .view_avaliations {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;

            & > h6 {
                display: flex !important;
                align-items: center !important;
                gap: 2px !important;
                margin-right: 6px !important;
                font-family: 'Roboto' !important;
                font-weight: 500 !important;
            }
        }

    }

    .body {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        align-items: flex-start !important;
        margin-top: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        border-bottom: 1px solid #d0d0d0 !important;

        & > :first-child {
            margin-bottom: 1rem !important;
            color: #2c2c2c !important;
            font-size: 1rem !important;
        }
        
        .author {
            display: flex !important;
            align-items: center !important;

            & > :first-child {
                width: 2rem !important;
                height: 2rem !important;
                margin-right: 0.4rem !important;
            }

            & > :last-child {
                font-size: 0.9rem !important;
            }
        }

        .answer_text {
            color: #2c2c2c !important;
            font-family: "Lato" !important;
        }
    }

    .footer_response_view {
        margin-top: 0.4rem !important;

        & > h6 {
            font-size: 0.9rem !important;
        }

        .area_avaliations {
            display: flex !important;
            align-items: center !important;
            margin: 0.6rem 0 !important;

            & > button {
                margin-right: 0.4rem !important;
                border-radius: 36px !important;
            }

            & > h6 {
                font-size: 1rem !important;
            }
        }
    }

    .comments {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-end !important;
        background-color: #f8f8f8 !important;

        min-height: 8rem !important;
        max-height: 18rem !important;

        .area_comments {
            max-height: 200px !important;
            margin-bottom: 0.8rem !important;

            overflow-y: auto !important;

            .comment {
                background-color: #ffc000 !important;
                border: 1px solid #2c2c2c7d !important;
                border-radius: 0.4rem !important;
                margin-bottom: 0.4rem !important;
                padding: 0.4rem !important;
                
                max-width: 50% !important;
            }

            .comment_author {
                background-color: #bd0e0e !important;
                color: #fff !important;
                border: 1px solid #2c2c2c7d !important;
                border-radius: 0.4rem !important;
                margin-bottom: 0.4rem !important;
                padding: 0.4rem !important;
                text-align: right !important;
                
                max-width: 50% !important;
                word-wrap: break-word !important;
            }

            .area_author_comment {
                border-bottom: 1px solid #2c2c2c50 !important;
                margin-bottom: 0.4rem !important;
                font-size: 0.8rem !important;
                font-family: "Roboto" !important;
                color: #2c2c2c !important;
                padding-left: 0.2rem !important;
            }

            .nothing_comment {
                text-align: center !important;
                color: #808080 !important;
            }
        }

        .commenting {
            display: flex !important;
            align-items: center !important;
            background-color: #fff !important;
            padding: 0.4rem !important;

            & > :first-child {
                width: 94% !important;
            }

            & > :last-child {
                background-color: #BD0E0E !important;
                color: #FFF !important;
                margin-left: 1rem !important;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .area_response {
        padding: 0.4rem !important;
        margin-bottom: 0.2rem !important;
    
        .header {
            & > :first-child {
               font-size: 0.9rem !important;
            }
    
            .view_avaliations {
                & > h6 {
                    font-size: 0.8rem !important;
                }
            }
    
        }
    
        .body {
            margin-top: 0 !important;
            padding-bottom: 0.4rem !important;
    
            & > :first-child {
                margin-bottom: 0.6rem !important;
                font-size: 0.9rem !important;
            }
            
            .author {
                display: flex !important;
                align-items: center !important;
    
                & > :first-child {
                    width: 1.8rem !important;
                    height: 1.8rem !important;
                    margin-right: 0.2rem !important;
                }
    
                & > :last-child {
                    font-size: 0.9rem !important;
                }
            }
        }
    
        .footer {
            margin-top: 0.4rem !important;
    
            & > h6 {
                font-size: 0.8rem !important;
            }
    
            .area_avaliations {
                & > button {
                    margin-right: 0.2rem !important;
                }
    
                & > h6 {
                    font-size: 0.9rem !important;
                }
            }
        }
    }
}