.header_page {
    width: 100% !important;
    height: 50vh !important;
    
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .iconSchool {
        font-size: 5.2rem !important;
        color: #FFF !important;
        border-radius: 9999px !important;
        padding: 8px !important;
    }

    .title_page {
        font-size: 3.6rem !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
        font-family: 'Roboto' !important;
        letter-spacing: 0.8rem !important;
        color: #2c2c2c !important;
    }

    .description_page {
        text-align: center !important;
        font-size: 1.4rem !important;
        color: #2c2c2c !important;
    }
}

@media screen and (max-width: 840px) {
    .header_page {
    
        .iconSchool {
            font-size: 2.8rem !important;
        }
    
        .title_page {
            font-size: 1.6rem !important;
            font-weight: 500 !important;
            text-transform: uppercase !important;
            font-family: 'Roboto' !important;
            letter-spacing: 0.8rem !important;
        }
    
        .description_page {
            text-align: center !important;
            font-size: 1rem !important;
        }
    }
}