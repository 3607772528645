.area_responses {
    width: 100% !important;
    max-height: 36rem !important;
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
        width: 12px;
    }
      
    &::-webkit-scrollbar-track {
        background: transparent !important;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc;
        border-radius: 36px;
        border: 4px solid #fff !important;
    }
}

@media screen and (max-width: 840px) {
    .area_responses {
        width: 100% !important;
        max-height: 20rem !important;
        overflow-y: scroll !important;
    
        &::-webkit-scrollbar {
            width: 0.4rem;
        }
          
        &::-webkit-scrollbar-track {
            background: transparent !important;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #bcbcbc;
            border-radius: 36px;
            border: 2px solid #fff !important;
        }
    }   
}