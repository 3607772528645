.area_question {
    border: 1px solid #2c2c2c !important;
    border-radius: 4px !important;
    padding: 0.6rem !important;
    display: flex !important;
    flex-direction: column !important;

    .header {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        flex-direction: row !important;
        padding: 0.4rem !important;

        border-bottom: 1px solid #d0d0d0 !important;
    
        .locale_question {

            & > :first-child {
                color: #BD0e0e !important;
                font-family: 'Roboto' !important;
                font-weight: 500 !important;
            }

            & > :last-child {
                color: #2c2c2c !important;
                font-family: 'Roboto' !important;
                font-weight: 500 !important;
            }
        }
    }

    .body {
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        margin-top: 1rem !important;

        & > :first-child {
            width: 2rem !important;
            height: 2rem !important;
            margin-right: 0.4rem !important;
        }
        
        & > :last-child {
            color: #2c2c2c !important;
            font-size: 0.9rem !important;
        }
    }
}

.divider_custom {
    display: flex !important;
    align-items: center !important;
    margin: 0.9rem 0 !important;

    & > :first-child {
        color: #6b6b6b !important;
        font-weight: 700 !important;
        font-size: 0.8rem !important;
    }

    &::before {
        content: "" !important;
        flex: 1 !important;
        height: 1px !important;
        background-color: #bebebe !important;
        margin-right: 0.4rem !important;
    }

    &::after {
        content: "" !important;
        flex: 1 !important;
        height: 1px !important;
        background-color: #bebebe !important;
        margin-left: 0.4rem !important;
    }
}

@media screen and (max-width: 840px) {
    .area_question {
        padding: 0.4rem !important;
    
        .header {
            padding: 0 !important;
        
            .locale_question {
    
                & > :first-child {
                    font-size: 0.9rem !important;
                }
    
                & > :last-child {
                    font-size: 0.9rem !important;
                }
            }
        }
    
        .body {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;
            margin-top: 1rem !important;
    
            & > :first-child {
                width: 1.8rem !important;
                height: 1.8rem !important;
                margin-right: 0.4rem !important;
            }
            
            & > :last-child {
                color: #2c2c2c !important;
                font-size: 0.8rem !important;
            }
        }
    }   
}