.learning_resources {
     border: 1px solid #d0d0d0 !important;
     border-radius: 8px !important;
    padding: 8px !important;
    padding: 12px 24px !important;

    // -- Lucas Was Here

    //background-color: #f8f8f8;
    // margin: 2.4rem 0 !important;
    // width: 100% !important;

    .header {
        width: 100% !important;
        margin-bottom: 1rem !important;

        .area_close {
            flex: 1 !important;
            border-radius: 8px !important;
            height: 100% !important;

            // .iconClose {
            //     // color: #BD0E0E !important;
            //     // font-size: 1.8rem !important;
            // }
        }

        .area_title_learning_resourses {
            flex: 4 !important;

            & > :first-child {
                font-size: 1.6rem !important;
                font-weight: 700 !important;
                text-transform: uppercase !important;
                text-align: center !important;
                margin-bottom: 0.8rem;
            }

            & > :last-child {
                font-size: 1rem !important;
                text-align: center !important;
                color: #565656 !important;
            }
        }

        .area_buttons {
            flex: 1 !important;

            & > button {
                margin-bottom: 0.8rem !important;
            }
        }
    }
}

@media (max-width: 840px) {
    .learning_resources {
        border-radius: 4px !important;
        padding: 4px !important;
        margin: 1rem !important;
    
        .header {
            .area_close {
                border-radius: 4px !important;

                .iconClose {
                    font-size: 1.6rem !important; 
                }
            }
    
            .area_title_learning_resourses {
                & > :first-child {
                    font-size: 1rem !important;
                    margin-bottom: 0.6rem;
                }
    
                & > :last-child {
                    font-size: 0.8rem !important;
                }
            }
    
            .area_buttons {
                align-items: flex-end !important;

                & > button {
                    margin-bottom: 0.2rem !important;
                    font-size: 0.6rem !important;
                    width: auto !important;
                }
            }
        }
    }   
}