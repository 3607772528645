.card-depoimento {
    text-align: justify;
    height: 180px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    span {
        font-weight: 500;
        font-size: 17.5px;
        text-transform: capitalize !important;
    }

    #subtitulo01 {
        font-weight: normal;
        font-size: 15.5px;
    }

    #subtitulo02 {
        font-weight: normal;
        font-size: 13.5px;
        color: rgba(0, 0, 0, 0.8);
    }
}

@media screen and (max-width: 1024px) {
    .card-depoimento {
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    .card-depoimento {
        height: 210px;
    }
}